
$bg-primary-dark: #48675e;
$bg-secondary-dark: #6a6282;
$bg-featured: #CEA6EA;
$bg-featured-dark: #2e2c39;
$bg-primary-light: #fdfdff;
$bg-secondary-light: #fdfdff;
$bg-header: #48675e;
$bg-footer: #2e2c39;

$bg-btn: #6a6282;
$bg-btn-hover: #E7E3F1;
$bg-btn-featured: #CEA6EA;
$bg-btn-featured-hover: #8FB56B;
$bg-site: #fdfdff;

$border-primary-dark: #2e2c39;
$border-secondary-dark: #6a6282;
$border-featured: #8FB56B;
$border-primary-light: #E7E3F1;
$border-secondary-light: rgba(46,44,57,0.25);

$text-primary-dark: #2e2c39;
$text-secondary-dark: #6a6282;
$text-featured-dark: #48675e;
$text-primary-light: #fdfdff;
$text-secondary-light: #CEA6EA;


