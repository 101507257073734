@use "../../styles/mixins" as *;

.accordion-item {
    &__title {
        font-family: 'Atkinson Hyperlegible', Arial, Helvetica, sans-serif;
        font-size: .875rem;
        text-transform: uppercase;
        font-weight: bold;

        @include tablet {
            font-size: 1rem;
        }
    }
}