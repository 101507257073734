@use "./_variables" as *;

@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1280px) {
    @content;
  }
}

// Flex
@mixin flex($justify: flex-start, $align: stretch) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
}

// List Reset 
@mixin list-reset {
  list-style-type: none;
  margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;

    &> li {
      display: inline;
    }
}