@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.project-preview {
    border: solid 1px $border-secondary-light;
    border-radius: 1.5rem;
    padding: 1rem 1.25rem 1.25rem;
    margin: 1rem;
    text-align: center;

    &__img {
        margin: 0 auto;
        width: 100%;
        height: auto;
    }

    &__title {
        font-size: 1.5rem;
        padding: .5rem 0 0;
        color: $text-secondary-dark;

        @include tablet {
            font-size: 1.625rem;
            padding: 1rem 0 .5rem;
        }
    }

    &__skills {
        font-size: 1rem;

        @include tablet {
            font-size: 1.125rem;
        }
    }

    &__btn {
        padding: .75rem 1rem;
        width: 95%;
        margin-top: 1rem;

        @include tablet {
            width: 95%;
        }
    }

    @include tablet {
        width: 45%;
        @include flex(space-between);
        flex-direction: column;
    }

    @include desktop {
        width: 30%;
    }
}