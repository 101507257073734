@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.site-header {
    background-color: $bg-header;
    padding: 2rem;
    width: 100%;

    @include flex(space-between, center);

    &__logo, &__logo > h1 {
        color: $text-primary-light;
        font-size: 1.25rem;
        height: auto;
        text-decoration: none;

        &:hover {
            color: $text-secondary-light;
        }
    }
}