@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;
 
.project {
    margin: 1rem;

    &__image {
        width: 100%;
        margin: 0 auto;
    }

    &__title {
        font-size: 1.75rem;
        margin: 0.5rem 0;
    }

    &__heading {
        font-size: 1.125rem;
        margin-bottom: 1rem;
    }

    &__details {
        margin: 0 0.5rem;

        @include tablet {
            margin: 0 2.5rem;
        }

        @include desktop {
            margin: 2rem 5rem;
        }
    }

    &__subheading {
        font-size: 1rem;
        color:$text-secondary-dark;
        text-transform: uppercase;

        &--light {
            color: $text-primary-light;

            @include desktop {
                font-size: 1.5rem;
            }
        }
    }

    &__detail {
        font-size: .875rem;
        margin: .25rem 0 1rem;

        @include tablet {
            font-size: 1rem;
        }
    }

    &__detail-list {
        font-size: .875rem;

        @include tablet {
            font-size: 1rem;
        }
    }

    &__detail-accordion {
        font-size: .875rem;

        @include tablet {
            font-size: 1rem;
        }
    }

    &__accordion {
        @include tablet {
            margin: 2rem 2.5rem;

        }

        @include desktop {
            margin: 2rem 5rem;
        }
    }

    &__button {
        margin-bottom: 1rem;

        @include tablet {
            margin-left: 2.5rem;
            margin-right: 2.5rem;
        }

        @include desktop {
            margin-left: 5rem;
            margin-right: 5rem;
        }
    }

    &__skill-list {
        @include list-reset;
        margin-bottom: 1rem;
        font-size: .875rem;

        &::before{
            content: " • ";
            color: $text-secondary-dark;
        }

        @include tablet {
            font-size: 1rem;
        }
    }

    &__skill{
        &::after{
            content: " • ";
            color: $text-secondary-dark;
        }
    }

    &__skill-logos-container {
        margin-bottom: 1rem;
    }

    &__skill-logo {
        height: 50px;
        width: auto;
        padding-right: .5rem;
    }

    &__related-projects {
        margin-top: 2rem;
        padding-top: 2rem;
        border-top: solid 2px $border-featured;
        text-align: center;

        @include tablet {
            text-align: left;
        }
    }

    &__related-projects-heading {
        @extend .project__title;


        @include tablet {
            padding-left: 1rem;
        }
    }

    &__related-projects-container {
        @include tablet {
            @include flex;
        }
    }

    @include tablet {
        margin: 2rem 4rem;
    }  

    @include desktop {
        margin: 4rem 10rem;
        max-width: 1450px;
    }
}