@use "./styles/mixins" as *;
@use "./styles/variables" as *;

// border-sizing reset
* {
  box-sizing: border-box;
}

// Margin resets
body {
  background-color: $bg-site;
  color: $text-primary-dark;
  font-family: 'Atkinson Hyperlegible', Arial, Helvetica, sans-serif;
  margin: 0;
}

figure,
h1,
h2,
h3,
h4,
h5,
p {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.button {
  width: 100%;
  padding: 1rem 2rem;
  text-decoration: none;
  background-color: $bg-btn;
  color: $text-primary-light;
  display: inline-block;
  border-radius: 1.5rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;

  &:hover {
    background-color: $bg-btn-featured;
    color: $text-secondary-dark;
  }

  &__featured {
    background-color: $bg-btn-featured;
    color: $text-secondary-dark;

    &:hover {
      background-color: $bg-btn;
      color: $text-secondary-light;
    }
  }

  &__alt {
    background-color: $bg-primary-light;
    color: $text-secondary-dark;
    border: solid 1px $border-secondary-dark;
    font-size: .75rem;
    width: fit-content;
    padding: 1rem;
    margin-right: 0.5rem;
  }

  @include tablet {
    width: fit-content;
    min-width: 9.25rem;
  }
}